@import './../../assets/styles/global/variables';

.c-layout__wrapper {
    padding     : 0 .375rem; 
    transition  : padding .25s $transition-easing; 
}

@media screen and (min-width: 769px) {
    .c-layout__wrapper {
        padding-left    : 5rem;
        padding-right   : 5rem;
        &--menu-open {
            padding-left: 17rem;
        }
    }
}
