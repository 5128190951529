.c-snackbar {
    position        : fixed;
    top             : 2.4rem;
    left            : 50%;
    z-index         : 1400;
    text-align      : center;
    width           : unset !important;
    transform       : translateX(-50%);

    &__inner {
        position        : relative;
        padding-left    : 3.2rem;
        padding-right   : 3.2rem;
        background-color: var(--font-color-inverted);
        box-shadow      : 0 0 0 .1rem #000 inset;
    }

    &__close-btn {
        position        : absolute;
        top             : 50%;
        right           : 6px;
        border          : 0;
        height          : 24px;
        padding         : 0;
        width           : 24px;
        transform       : translate(0%, -50%);
        background      : transparent;
        line-height     : 2;

        &:focus {
          outline: unset;
        }

        &:hover {
            background: rgba(0, 0, 0, .1);
        }

        &:active {
            background: rgba(0, 0, 0, .2);
        }
    }

    &--success .c-snackbar__inner {
        color       : var(--color-green);
        box-shadow  : 0 0 0 .1rem var(--color-green) inset;
    }

    &--warning .c-snackbar__inner {
        color       : var(--color-orange);
        box-shadow  : 0 0 0 .1rem var(--color-orange) inset;
    }

    &--error .c-snackbar__inner {
        color       : var(--color-red);
        box-shadow  : 0 0 0 .1rem var(--color-red) inset;
    }
}

@media screen and (max-width: 600px) {
    .c-snackbar {
        width : 100% !important;
    }
}
