.c-theme-switch {
    &--light {
        margin-top: 2px;
        color: #ffc107;
    }

    &--dark {
        margin-top: 2px;
        color: #cfd8dc;
    }
}