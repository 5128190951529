.c-loader {
    width   : 100%;
    height  : 100%;
    color   : var(--font-color-base);

    svg {
        width       : 10rem;
        height      : 10rem;
        margin      : 2rem;
        display     : inline-block;
        stroke      : var(--font-color-base);
    }

    &__fullpage-wrapper {
        width   : 100%;
        height  : calc(100vh - 10rem);
    }
}
