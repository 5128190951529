@import './../../../assets/styles/global/variables';

.c-drawer {
    position    : fixed;
    top         : 0;
    left        : 0; 
    z-index     : 999;
    height      : 100vh;
    transition  : width .25s $transition-easing;

    &--open {  
        width: 15rem !important;
        .nested {
            padding-left: 36px;
        }
    }
    &--close {
        width       : 3.5rem;
        // overflow-x  : hidden;
    }

    & &__paper-root {
        background-color: var(--color-accent);
        color           : var(--font-color-inverted);
        transition      : width .25s $transition-easing;
        overflow-x      : hidden;
    }

    & &__bottom-links {
        margin-top: auto;
    }

    &__typography {
        font-size: 1.4rem;
    }

    &__icon,
    &__text {
        color: var(--font-color-inverted);
        & > * {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 134px;
        }
    }
    &__sns {
        margin-left: 15px;
    }

    &__logo {
        width: 100%;
    }

    .nested {
        transition: padding-left .15s $transition-easing;
        width: 15rem !important;
    }
}

.c-drawer__menu-btn_hack {
    color: #72BA3E!important;
    z-index: 9999;
    display: none;
}

.c-drawer__account-menu-fix {
    text-align  : center;
    outline     : unset;
}

@media screen and (max-width: 768px) {
    .c-drawer {
        & &--close {
            left: -3.5rem;

            & .c-drawer__menu-btn .c-drawer__text {
                display: none;
            }

            & .c-drawer__menu-btn .c-drawer__logo {
                display: none;
            }

            & .c-drawer__menu-btn .c-drawer__icon {
                color: #72BA3E;
            }

        }

        &:not(.c-drawer--close) .c-drawer__logo  {
            position: fixed;
            top: 6px;
            left: 70px;
            height: 37px;
            width: auto;
            z-index: 999;
        }

        & .c-drawer__logo  {
            width: auto;
        }

        &:not(.c-drawer--close) { 
            .c-drawer__sns  {
                margin-left: 10px;
            }
            .c-drawer__menu-btn {
                &::before {
                    width: 240px;
                    transition: width .25s linear;
                }
            }
        }
        
        &__menu-btn {
            position: fixed;
            top     : 12px; 
            left    : 16px;
            z-index : 998;
            & > * {
                position: relative;
                z-index: 2;
            }
            &::before {
                content: "";
                position: absolute;
                left: -16px;
                bottom:-4px;
                width: 0;
                height: 250px;
                background-color: #72BA3E;
                z-index: 1;
            }
        }
    }

    .media-links {
        margin-top: 20px;
    }
}

