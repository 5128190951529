
:root {
    --font-color-base       : #000;
    --font-color-inverted   : #fff;
    --font-family           : 'Roboto', Quicksand,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    --font-size-base        : 1.6rem;
    --color-base            : #fff;

    --spacing-base          : .3rem;

    //the following are the default values for all the theme-related color variables; they are here as fallbacks in case another theme is incomplete.
    --color-accent              : #72BA3E;
    --color-accent-inverted     : orange; 
    --color-base-0              : #fff; 
    --color-base-1              : #eee;
    --color-base-2              : #fff;
    --color-base-3              : #eee;
    --color-border-base         : #bebebe; 
    --color-green               : #00b159; 
    --color-orange              : orange;
    --color-red                 : #ff4136; 
    --color-off-base            : #efefef;
    --color-white               : #fff;
    --color-black               : #000;

    --base-transparency-two-thirds : rgba(255, 255, 255, .66);
    --base-transparencry-90-percent: rgba(255, 255, 255, .9);
    --inverted-transparencry-10-percent: rgba(0, 0, 0, .1);
}
