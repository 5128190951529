@import './../global/variables';

.u-transition {
    &--lateral {
        &-enter,
        &-exit {
            transform   : translate3d(0, 0, 0);
        }

        &-enter {
            opacity     : 0;
            transform   : translate3d(4rem, 0, 0);
        }

        &-enter-active {
            transform   : translate3d(0, 0, 0);
            opacity     : 1;
            transition  : opacity   .5s linear,
                          transform .5s $transition-easing; 
        }
        &-exit-active {
            transform   : translate3d(-4rem, 0, 0);
            opacity     : 0;
            transition  : opacity   .5s linear,
                          transform .5s $transition-easing;
        }
    }
    
    &--vertical {
        &-enter,
        &-exit {
            transform   : translate3d(0, 0, 0);
        }

        &-enter {
            opacity     : 0;
            transform   : translate3d(0,4rem, 0);
        }

        &-enter-active {
            transform   : translate3d(0, 0, 0);
            opacity     : 1;
            transition  : opacity   .5s linear,
                          transform .5s $transition-easing; 
        }
        &-exit-active {
            transform   : translate3d(0, -4rem, 0);
            opacity     : 0;
            transition  : opacity   .5s linear,
                          transform .5s $transition-easing;
        }
    }
    
    &--zoom {
        &-enter,
        &-exit {
            transition: opacity   .25s linear, 
                        transform .25s $transition-easing; 
        }

        &-enter {
            opacity         : 0; 
            transform       : scale(.95);
            transition-delay: .05s, .05s; 
        }
        
        &-enter-active  { 
            opacity     : 1; 
            transform   : scale(1);
        }
        
        &-exit-active {
            transform   : scale(1.05);
            opacity     : 0;
            transition  : opacity   .25s linear, 
                          transform .25s $transition-easing; 
        }
    }
}